<script>
import { Line } from 'vue-chartjs';
import moment from 'moment-business-days';

export default {
  extends: Line,
  props: {
    layers: {
      type: Array,
      default: () => []
    },
    labels: {
      type: Array,
      default: () => []
    },
    leftYAxisLabel: {
      type: Array,
      default: () => []
    },
    rightYAxisLabel: {
      type: Array,
      default: () => []
    },
    leftYAxisMinMax: {
      type: Array,
      default: () => []
    },
    rightYAxisMinMax: {
      type: Array,
      default: () => []
    },
    windDirection: {
      type: Boolean,
      default: false
    },
    step: {
      type: Number,
      default: 7
    }
  },
  data() {
    return {
      chartdata: {},
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          mode: 'index',
          intersect: false
        },
        hover: {
          mode: 'index',
          intersect: false,
          animationDuration: 0
        },
        legend: {
          position: 'bottom'
        },
        layout: {
          padding: {
            top: 30
          }
        },
        scales: {
          yAxes: [
            {
              id: 'y-axis-1',
              display: true,
              type: 'linear',
              position: 'left',
              ticks: {
                suggestedMin: this.leftYAxisMinMax[0],
                suggestedMax: this.leftYAxisMinMax[1],
                display: true
              },
              gridLines: {
                drawTicks: true,
                display: true
              },
              scaleLabel: {
                display: true,
                labelString: this.leftYAxisLabel[0]
              },
              afterUpdate: function(axes) {
                axes.width = axes.width > 60 ? axes.width : 60;
              }
            },
            {
              id: 'y-axis-1-bis',
              display: this.leftYAxisLabel[1],
              type: 'linear',
              position: 'left',
              ticks: {
                suggestedMin: this.leftYAxisMinMax[0],
                suggestedMax: this.leftYAxisMinMax[1],
                display: false
              },
              gridLines: {
                drawTicks: false,
                display: false
              },
              scaleLabel: {
                display: true,
                labelString: this.leftYAxisLabel[1]
              }
            },
            {
              id: 'y-axis-2',
              display: this.rightYAxisLabel[0],
              type: 'linear',
              position: 'right',
              ticks: {
                suggestedMin: this.rightYAxisMinMax[0],
                suggestedMax: this.rightYAxisMinMax[1],
                display: true
              },
              gridLines: {
                display: false,
                drawOnChartArea: false
              },
              scaleLabel: {
                display: true,
                labelString: this.rightYAxisLabel[0]
              }
            },
            {
              id: 'y-axis-2-bis',
              display: this.rightYAxisLabel[1],
              type: 'linear',
              position: 'right',
              ticks: {
                suggestedMin: this.rightYAxisMinMax[0],
                suggestedMax: this.rightYAxisMinMax[1],
                display: false
              },
              gridLines: {
                drawTicks: false,
                display: false
              },
              scaleLabel: {
                display: true,
                labelString: this.rightYAxisLabel[1]
              }
            }
          ],
          xAxes: [
            {
              id: 'x-axis-1',
              ticks: {
                display: false
              },
              gridLines: {
                drawTicks: false,
                display: false
              }
            },
            {
              id: 'x-axis-2',
              ticks: {
                display: true,
                callback: function (value) {
                  return value.split(' ')[1];
                }
              }
            }
          ]
        }
      }
    };
  },
  computed: {},
  mounted() {
    if (this.windDirection) {
      if (this.leftYAxisLabel.length > 0) {
        this.addEventListeners();
        this.separateDaysPlugin();
      } else {
        this.changeChartOptions();
        this.drawArrowsPlugin();
      }
    } else {
      this.separateDaysPlugin();
    }

    this.chartdata = {
      labels: this.labels,
      datasets: this.layers
    };

    this.renderChart(this.chartdata, this.options);
  },
  methods: {
    addEventListeners() {
      this.$refs.canvas.addEventListener('mousemove', () => {
        this.$emit('mouse-entered', true);
      });
      this.$refs.canvas.addEventListener('mouseout', () => {
        this.$emit('mouse-entered', false);
      });
    },
    changeChartOptions() {
      this.options = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          yAxes: [
            {
              id: 'y-axis-1',
              display: true,
              type: 'linear',
              position: 'left',
              ticks: {
                display: false
              },
              gridLines: {
                drawTicks: false,
                display: false
              },
              scaleLabel: {
                display: false
              },
              afterUpdate: function(axes) {
                axes.width = 66;
              }
            }
          ],
          xAxes: [
            {
              id: 'x-axis-1',
              ticks: {
                display: false
              },
              gridLines: {
                drawTicks: false,
                display: false
              }
            }
          ]
        }
      };
    },
    separateDaysPlugin() {
      this.addPlugin({
        beforeDatasetsDraw: chart => {
          const ctx = chart.ctx;
          const xAxis = chart.scales['x-axis-1'];
          const yAxis = chart.scales['y-axis-1'];
          const tickGap = xAxis.getPixelForTick(1) - xAxis.getPixelForTick(0);

          ctx.save();
          let previousDate = null;
          let currentBackgroundColor = 'rgba(255, 255, 255, 0)';

          xAxis.ticks.forEach((tick, index) => {
            if (index === 0) {
              previousDate = tick.split(' ')[0];

              return;
            }

            const currentDate = tick.split(' ')[0];
            const sameDay = previousDate === currentDate;
            previousDate = currentDate;

            if (!sameDay) {
              currentBackgroundColor = currentBackgroundColor === 'rgba(255, 255, 255, 0)'
                ? 'rgba(230, 246, 236, 1)'
                : 'rgba(255, 255, 255, 0)';
            }

            const endTickGap = index === xAxis.ticks.length - 1 ? 0 : tickGap * 1.5;
            const startX = xAxis.getPixelForTick(index - 1) + tickGap;
            const endX = xAxis.getPixelForTick(index) + endTickGap;

            ctx.fillStyle = currentBackgroundColor;
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillRect(startX, yAxis.top, endX - startX, yAxis.bottom - yAxis.top);

            if (tick.split(' ')[1] === '12:00') {
              const format = this.step === 15 ? 'D MMM' : 'ddd D MMM';
              const text = moment(tick.split(' ')[0], 'DD-MM-YYYY').format(format).replace(/\b\w/g, (l) => l.toUpperCase());
              const textX = startX + (endX - startX) / 2 - 10;
              const textY = yAxis.top - 15;
              ctx.fillStyle = '#0da344';
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              ctx.globalCompositeOperation = 'source-over';
              ctx.fillText(text, textX, textY);
            }
          });

          ctx.restore();
        }
      });
    },
    drawArrowsPlugin() {
      this.addPlugin({
        beforeDatasetsDraw: chart => {
          const ctx = chart.ctx;
          const xAxis = chart.scales['x-axis-1'];
          const yAxis = chart.scales['y-axis-1'];

          const svgCode = `
            <svg fill="#000000" width="15px" height="15px" viewBox="0 0 0.281 0.281" xmlns="http://www.w3.org/2000/svg" id="arrow" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" transform="matrix(6.123233995736766e-17,1,-1,6.123233995736766e-17,0,0)">
              <path d="M0.155 0.043a0.019 0.019 0 0 1 0.027 0l0.084 0.084a0.019 0.019 0 0 1 0 0.027l-0.084 0.084a0.019 0.019 0 0 1 -0.027 -0.027L0.206 0.159H0.028a0.019 0.019 0 0 1 0 -0.037H0.206L0.155 0.07a0.019 0.019 0 0 1 0 -0.027Z"/>
            </svg>
          `;

          const svgDataUrl = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgCode)}`;
          const img = new Image();

          img.onload = () => {
            xAxis.ticks.forEach((tick, index) => {
              if (index % 5 === 0 && index !== 0) {
                const svgX = xAxis.getPixelForTick(index) - 15;
                const svgY = yAxis.top + 50;

                const datasetIndex = 0;
                const value = chart.data.datasets[datasetIndex].data[index];
                const rotationAngle = value * Math.PI / 180;

                ctx.save();
                ctx.translate(svgX + 10, svgY + 10);
                ctx.rotate(rotationAngle);
                ctx.drawImage(img, -10, -10, 20, 20);
                ctx.restore();
              }
            });
          };

          img.src = svgDataUrl;
        }
      });
    }
  }
};
</script>
